import React from "react"
import PropTypes from "prop-types"
import { Link, graphql } from "gatsby"
import tw, { styled } from "twin.macro"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import Section from "../components/section/section"
import ArticleLargeCard from "../components/article/article-large-card"

const _ = require("lodash")

const ArticleCards = styled.div`
    ${tw`flex flex-wrap -mx-3 lg:-mx-6 -mt-6`}
`

const Pagination = styled.nav`
    ${tw`text-center text-white mt-6 py-4`}
`

const PaginationList = styled.ul`
    ${tw`list-none inline-block`}
`

const PaginationLink = styled.li`
    ${tw`float-left p-2`}
`

const StyledLink = styled(Link)`
    ${tw`w-full h-full block w-12 h-12 p-2 bg-gray-400 rounded-lg hover:bg-psb-blue-200
        transition-colors duration-300 focus:outline-none focus:bg-psb-blue-200 text-white text-lg font-bold`}
`

const CategoryTemplate = ({ data, pageContext, location }) => {
    const { category, currentPage, numberOfPages } = pageContext
    const catPath = `/category/${_.kebabCase(category)}`

    const isFirstPage = currentPage === 1
    const isLastPage = currentPage === numberOfPages
    const previousPage = currentPage - 1 === 1 ? catPath : `${catPath}/${(currentPage - 1).toString()}`
    const nextPage = `${catPath}/${(currentPage + 1).toString()}`
    
    const { edges } = data.allMdx

    var categorySnippet
    var categoryText

    if (category === "Quick Tip") {
        categorySnippet = "Quick hints and tips to help you become more productive and get to grips with taking and editing better photos and videos."
        categoryText = "Welcome to the Quick Tip category. With the ever increasing complexity of the camera and editing applications we use and our lack of time to read through long and boring manuals, we bring relevant, interesting and helpful hints and tips so that we can get the most from our phones and applications."
    } else if (category === "Recommendation") {
        categorySnippet = "Detailed reviews and analysis of products to help you make a more informed buying decision."
        categoryText = "Welcome to the Recommendation category. We spend a long time with each product to really understand how good (or bad) it really is. We are often disappointed with products that seem to be best sellers, only to find they have serious flaws or do not have the features or build quality we would expect."
    } else if (category === "Tutorial") {
        categorySnippet = "Longer and more detailed tutorials and walkthroughs provide a deeper dive into products and their technology."
        categoryText = "Welcome to the Tutorial category. Not everything can be explained in a few paragraphs or by a simple diagram. Our tutorials go deeper into the technology behind our favourite products and help to provide some context and meaning behind product features and terminology."
    } else if (category === "Deal") {
        categorySnippet = "We find the best deals across a wide range of merchants to help you get the best possible price."
        categoryText = "Welcome to the Deal category. We only include deals from merchants that we would personally use to buy our technology. We do not simply look for the cheapest price, but consider reputation, shipping, and return policy in order to filter out deals that are simply too good to be true."
    }

    return (
        <Layout>
            <SEO
                title={category}
                pathname={location.pathname}
            />
            <Hero title={category} description={categorySnippet} image={data.hero.childImageSharp.fluid} />
            <Section>
                <p>{categoryText}</p>
                <ArticleCards>
                    {edges.map(({ node }) => (
                        <ArticleLargeCard
                            title={node.frontmatter.title}
                            date={node.frontmatter.date}
                            categories={node.frontmatter.categories}
                            tags={node.frontmatter.tags}
                            featuredImage={node.frontmatter.featuredImage.childImageSharp.fluid}
                            slug={node.fields.slug} />
                    ))}
                    </ArticleCards>
                    <Pagination>
                        <PaginationList>
                            {!isFirstPage && (
                                <PaginationLink>
                                    <button>
                                        <StyledLink
                                            to={previousPage}
                                            rel="prev"
                                            activeStyle={{ backgroundColor: "rgba(255,159,10,var(--bg-opacity))"}}>
                                                &lt;
                                        </StyledLink>
                                    </button>
                                </PaginationLink>
                            )}
                            {Array.from({ length: numberOfPages }, (_, i) => (
                                <PaginationLink>
                                    <button>
                                        <StyledLink
                                            key={`pagination-number${i + 1}`}
                                            to={`${catPath}${ i === 0 ? "" : "/" +(i + 1)}`}
                                            activeStyle={{ backgroundColor: "rgba(255,159,10,var(--bg-opacity))"}}>
                                                {i + 1}
                                        </StyledLink>
                                    </button>
                                </PaginationLink>
                            ))}
                            {!isLastPage && (
                                <PaginationLink>
                                    <button>
                                        <StyledLink
                                            to={nextPage}
                                            rel="next"
                                            activeStyle={{ backgroundColor: "rgba(255,159,10,var(--bg-opacity))"}}>
                                                &gt;
                                        </StyledLink>
                                    </button>
                                </PaginationLink>
                            )}
                        </PaginationList>
                    </Pagination>
            </Section>
        </Layout>
    )
}

CategoryTemplate.propTypes = {
    pageContext: PropTypes.shape({
        category: PropTypes.string.isRequired,
    }),
    data: PropTypes.shape({
        allMdx: PropTypes.shape({
            edges: PropTypes.arrayOf(
                PropTypes.shape({
                    node: PropTypes.shape({
                        frontmatter: PropTypes.shape({
                            title: PropTypes.string.isRequired,
                        }),
                        fields: PropTypes.shape({
                            slug: PropTypes.string.isRequired,
                        }),
                    }),
                }).isRequired
            ),
        }),
    }),
}

export default CategoryTemplate

export const categoryPageListQuery = graphql`
    query categoryPageListQuery($category: String, $skip: Int!, $limit: Int!) {
        allMdx (
            sort: { fields: [frontmatter___date], order: DESC }
            filter: { frontmatter: { categories: { in: [$category] }, published: { eq: true } } }
            limit: $limit
            skip: $skip
        ) {
            edges {
                node {
                    fields {
                        slug
                    }
                    frontmatter {
                        title
                        date(formatString: "DD MMMM, YYYY")
                        categories
                        tags
                        featuredImage {
                            childImageSharp {
                                fluid(maxWidth: 1200) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                }
            }
        }
        hero: file(relativePath: { eq: "mirage-searching_400.png" }) {
            childImageSharp {
                fluid(maxWidth: 1216 quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`